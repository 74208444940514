/* Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. */
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import Vue from "vue";
import i18n from "libs/i18n";
import language from "libs/language";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import apiLibrary from "libs/api/api.js";
import helper from "libs/helper";
import site from "config/site";
import seo from "libs/seo.js";
import "libs/ssr.js";
import "libs/nl2br";
Vue.prototype.$seo = seo;
import VueMeta from "vue-meta";
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
import VueRouter from "vue-router";

import $ from "jquery";
import { textRequestByApi } from "config/env.json";
Vue.config.productionTip = false;

window.$ = $;

import mainBtn from "@/components/button/mainBtn.vue";
Vue.component("Main-btn", mainBtn);
import typeBBtn from "@/components/button/typeBBtn.vue";
Vue.component("TypeB-btn", typeBBtn);
import typeCBtn from "@/components/button/typeCBtn.vue";
Vue.component("TypeC-btn", typeCBtn);
import typeDBtn from "@/components/button/typeDBtn.vue";
Vue.component("TypeD-btn", typeDBtn);
import MainTop from "@/components/main/mainTop.vue";
Vue.component("Main-top", MainTop);
import SearchBox from "@/components/searchBox.vue";
Vue.component("SearchBox", SearchBox);

import linkNode from "@/components/linkNode/linkNode.vue";
Vue.component("link-node", linkNode);

class Application {
  async launch() {
    window.rootComponent = new Vue({
      i18n,
      metaInfo() {
        return {
          titleTemplate: (titleChunk) => {
            return titleChunk
              ? `${titleChunk} | ${this.siteName}`
              : this.siteName;
          },
          title: this.vueMeta.title,
          link: this.vueMeta.link,
          meta: this.vueMeta.meta,
          script: this.vueMeta.script,
          // htmlAttrs: {
          //   lang: this.language,
          // },
        };
      },
      data: () => ({
        vueMeta: {
          title: "",
          link: [],
          meta: [],
          script: [],
        },
        frontendText: null,
      }),
      async created() {
        this.initKernel();
        this.initPluginChunks();
        this.detectRouterPush();
      },
      methods: {
        detectRouterPush() {
          const _this = this;
          const originalPush = VueRouter.prototype.push;
          VueRouter.prototype.push = function(to) {
            const samePage = _this.isSamePage(to);
            if (samePage) {
              window.location.reload();
              return;
            }
            return originalPush.call(this, to).catch((err) => {
              console.warn(err);
            });
          };
        },
        isSamePage(to) {
          const cloneTo = _cloneDeep(to);
          if (!cloneTo.query) {
            cloneTo.query = {};
          }

          if(!cloneTo.params) {
            cloneTo.params = {}
          }
          if(!cloneTo.params.siteCode) {
            cloneTo.params.siteCode = this.$route.params.siteCode;
          }

          const from = {
            name: this.$route.name,
            params: this.$route.params,
            query: this.$route.query,
          };

          // console.log(cloneTo, from);

          return _isEqual(cloneTo, from);
        },
        async initKernel() {
          window.helper = helper;
          Vue.prototype.$helper = helper;
          // 註冊api library到vue prototype讓每個vue元件可使用api
          Vue.prototype.$api = await apiLibrary();

          await this.initApplication();
          this.setupI18n();
          window.rootComponent.$mount("#app");
        },
        async initApplication() {
          await this.initApplicationRequest();
          await this.loadFrontendText();
        },
        async initApplicationRequest() {
          this.$store.dispatch(`base/set`, {
            key: "applicationReady",
            value: false,
          });
          await this.$nextTick();
          await this.$helper.delay(0.2);

          if (!this.siteCode) {
            this.$router.replace({
              path: `/${site.siteCodeFallback}`,
            });
          }
         
          
          const siteCode = this.siteCode || site.siteCodeFallback;
          try {
            const applicationData = await this.$api.application.read(siteCode);
            const siteId = window._get(applicationData, ["site_data", "id"]);
            window.siteId = siteId;
            this.$store.dispatch("base/set", { key: "siteId", value: siteId });
            this.$store.dispatch("base/setApplication", applicationData);
            await this.$nextTick();
            this.setupMeta();
            // 沒有site_data => 404
            if (!applicationData.site_data) {
              this.$router.replace({ name: "404" });
              await this.$helper.delay(0.5);
              this.$store.dispatch(`base/set`, {
                key: "applicationReady",
                value: true,
              });
              return;
            }
            // use vue route to deal with this redirect
            // if (applicationData.site_data.status == 0 ) {
            //   this.$router.replace({ name: "develop" });
            //   await this.$helper.delay(0.5);
            //   this.$store.dispatch(`base/set`, {
            //     key: "applicationReady",
            //     value: true,
            //   });
            //   return;
            // }
          } catch (error) {
            console.error(error);
          }
          this.$store.dispatch(`base/set`, {
            key: "applicationReady",
            value: true,
          });
        },
        setupMeta() {
          if (!this.siteData) return;
          this.vueMeta.title = this.pageTitle;
          this.vueMeta.meta = this.$seo.getMetaArray({
            title: this.pageTitle,
            description: this.seoDescription,
            keywords: this.seoKeywords,
            photo: this.seoPhoto,
          })
          const url = this.seoCanonicalTag || `${window.location.origin}${this.$route.path}`
          this.vueMeta.link = [{ vmid: 'canonical', rel: "canonical", href: url }]
        },
        async loadFrontendText() {
          if (!this.siteData) return;
          if (textRequestByApi === true) {
            try {
              // console.log(this.siteData.language)
              this.frontendText = await this.$api.base.getTextJson(
                this.siteData.language,
                this.siteData.language_json
              );
            } catch (error) {
              console.error(error);
            }

            return;
          }

          const textUrl = window._get(this.siteData, "text_url");
          try {
            this.frontendText = await this.$api.base.remoteJson(textUrl);
          } catch (error) {
            console.error(error);
          }
        },
        setupI18n() {
          this.$i18n.locale = this.lang;
          this.$vuetify.lang.current = this.lang;
          // 到時候拿來加入api取得的i18n翻譯檔
          this.$i18n.mergeLocaleMessage(this.$i18n.locale, this.frontendText);
          // @ts-ignore
          Vue.filter("t", (...args) => this.$t(...args));
          // @ts-ignore
          Vue.filter("tc", (...args) => this.$tc(...args));
          // @ts-ignore
          Vue.filter("te", (...args) => this.$te(...args));
        },
        async _loadValiadtor() {
          // https://github.com/validatorjs/validator.js
          const { default: validator } = await import("validator");
          Vue.prototype.$validator = validator;
        },
        async initPluginChunks() {
          this._loadValiadtor();
        },
      },
      router,
      store,
      vuetify,
      render: (h) => h(App),
      computed: {
        siteData() {
          return this.$store.getters["base/siteData"];
        },
        lang() {
          if (!this.siteData) return site.siteCodeFallback;
          return this.siteData.language;
        },
        siteTextUrl() {
          return this.$store.getters["base/siteTextUrl"];
        },
        siteCode() {
          return this.$route.params.siteCode;
        },
        siteName() {
          return this.$store.getters["base/siteName"];
        },
        pageTitle() {
          return this.seoTitle;
        },
        seoTitle() {
          return this.$store.getters["base/seoTitle"];
        },
        seoDescription() {
          return this.$store.getters["base/seoDescription"];
        },
        seoKeywords() {
          return this.$store.getters["base/seoKeywords"];
        },
        seoCanonicalTag() {
          return this.$store.getters["base/seoCanonicalTag"];
        },
        seoPhoto() {
          return this.$store.getters["base/seoPhoto"];
        },
      },
    });
  }
}

new Application().launch();
